import React from "react"

type Props = {
  menu?: boolean
}

function SkipLinks({ menu }: Props): JSX.Element {
  return (
    <>
      <nav>
        <ul className="skipLinks">
          {menu && (
            <li>
              <a className="link-skip-to-content" href="#menu-top">
                Aller au menu
              </a>
            </li>
          )}
          <li>
            <a className="link-skip-to-content" href="#main">
              Aller au contenu
            </a>
          </li>
          <li>
            <a className="link-skip-to-content" href="#footer">
              Aller au pied de page
            </a>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default SkipLinks
