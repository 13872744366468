import React from "react"

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      role="button"
      aria-label="Augmenter le nombre de jours"
      style={{ MsTransform: "rotate(360deg)" }}
      transform="rotate(360)"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#626262"
        d="M16 10c0 .553-.048 1-.601 1H11v4.399c0 .552-.447.601-1 .601-.553 0-1-.049-1-.601V11H4.601C4.049 11 4 10.553 4 10c0-.553.049-1 .601-1H9V4.601C9 4.048 9.447 4 10 4c.553 0 1 .048 1 .601V9h4.399c.553 0 .601.447.601 1z"
      />
    </svg>
  )
}

export default Icon
